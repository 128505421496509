/* styles.css or in a style tag in your component */
.custom-scrollbar {
    overflow-y: scroll !important; /* Force scrollbar to always be visible */
    scrollbar-color: #EB0728 #E5E5E5; /* For browsers that support this property */
    scrollbar-width: thin; /* For Firefox */
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #E5E5E5; /* Gutter background color */
    border-radius: 80px; /* Gutter border radius */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #EB0728; /* Scrollbar handle color */
    border-radius: 80px; /* Scrollbar handle border radius */
  }
  