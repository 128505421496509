.checkmark {
    position: absolute;
    height: 20px; /* Adjust the size of the checkbox */
    width: 20px; /* Adjust the size of the checkbox */
    background-color: #D9D9D9; /* Default background color */
    border-radius: 5px;
  }

  .checkmark1 {
    position: absolute;
    height: 20px; /* Adjust the size of the checkbox */
    width: 20px; /* Adjust the size of the checkbox */
    background-color: #D9D9D9; /* Default background color */
    border-radius: 5px;
    opacity: 50%;
  }
  
  /* Style the checkmark when the checkbox is checked */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #EB0728; /* Background color when checked */
  }
  
  /* Style the checkmark icon */
  .checkmark:after, .checkmark1:after {
    content: '';
    position: absolute;
    display: none;
  }
  
  /* Style the checkmark icon when the checkbox is checked */
  .checkbox-container input:checked ~ .checkmark:after, .checkmark1:after {
    display: block;
  }
  
  /* Customize the checkmark icon (you can use an icon or Unicode character here) */
  .checkbox-container input:checked ~ .checkmark:after {
    content: '\2713'; /* Unicode character for a checkmark */
    color: white; /* Color of the checkmark */
    font-size: 16px; /* Adjust the font size as needed */
    margin-left: 2px;
    margin-top: -2px;
    font-weight: 700;
  }
  
    /* Customize the checkmark icon (you can use an icon or Unicode character here) */
    .checkbox-container input:checked ~ .checkmark1:after {
        content: '\2713'; /* Unicode character for a checkmark */
        color: white; /* Color of the checkmark */
        font-size: 16px; /* Adjust the font size as needed */
        margin-left: 2px;
        margin-top: -2px;
        font-weight: 700;
      }
  
      
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
  }

@media screen and (min-width: 1288px) {
    .smallContainer{
        width: 340px;
    }
}
@media screen and (max-width: 1123px) {
    .smallContainer{
        width: 270px;
    }
}

@media screen and (max-width: 385px) {
    .smallCity{
        margin-top: 38px;
    }
   .userImage{
    margin-top: -12.187rem;
   }
    .smallPrice{
        justify-content: center;
        align-items: center;
        display: flex;
        padding-right: 0px;
    }
    .smallDetails{
        gap: 10px;
        margin-top: 18px;
    }
}

/* @media screen and (max-width: 446px) {
    .smallContainer{
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }
    .smallImage, .smallItems{
        width: 100%;
        height: fit-content;
    }
    .smallAirportImg{
        height: 160px;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 0px;
    }
    .smallItems{
        margin-top: 120px;
    }
    .smallUser{
        margin-top: 60px;
    }
    .smalllDeviceItem{
        display: flex;
        flex-wrap: wrap;
    }
} */