@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('./../public/fonts/Figtree-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}


body {
  margin: 0;
  font-family: 'Figtree', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Figtree', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

.fill-available {
  width: -webkit-fill-available;
}

.closeButton{
  background-color: red;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
  transition: all 0.15s ease-in-out 0s;
}

.shadow-market {
  --tw-shadow: 0 0px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.main-container {
  transition: margin-top 0.3s ease;
}
