@media screen and (max-width: 400px) {
    .smallDevice{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media screen and (max-width: 480px){
    .smallName{
        flex-direction: row;
        gap: 15px;
    }
}